import React from "react"
import Layout from "../../components/layout"
import { Link } from "gatsby"
import track from "../../components/track"
import amPm from "../../images/infographic-ampm.png"
import amPm2x from "../../images/infographic-ampm@2x.png"


const Dosing = () => {

  const pageTitle = 'About MOVANTIK';
  const pageDescription = 'See how MOVANTIK can treat opioid-induced constipation and how quickly it can help relieve symptoms.';
  const pageName = "About MOVANTIK";

  return (
    <Layout pageTitle={pageTitle} pageDescription={pageDescription} pageType="patient" pageSection="about" pageClass="about"  pageName={pageName}>
      <div className="page-title">
        <div className="container">About MOVANTIK</div>
      </div>
      <div className="container">
        <h1>Rapid response with MOVANTIK</h1>
      </div>
      <div className="headline-divider"></div>
      <div className="container">
        <h2>In adults with chronic non-cancer pain, <span className="bold">MOVANTIK 25 mg was shown to be effective for OIC in 2 clinical studies.<sup>1,2</sup></span></h2>
        <ul>
          <li>In these studies, MOVANTIK worked quickly, often within 6-12 hours of the first dose<sup>1</sup></li>
        </ul>
        
        <div className="center infographic">
        <img src={amPm2x} alt="MOVANTIK in the AM may lead to Bowel movement in the PM" />
        {/* <picture>
            <source srcset="Path.Extension, Path@2x.Extension 2x">
            <img src="Path.Extension" alt="">
        </picture> */}
          {/* <Link to="/savings/" id="patient-dosing-btn-savings" className="button" onClick={() => track("patient Dosing page", "button click", "DTC About Movantik-Savings Card Button")}>
            <div className="btn-text">Find out if you can save on your prescription<br />with the <span className="bold">MOVANTIK Savings Card</span></div>
            <div className="btn-arrow"></div>
          </Link> */}
        </div>

        <div className="end-matter">
        <div className="footnotes">
          <div className="references"><span className="bold">References: 1.</span>&nbsp;MOVANTIK<sup>&reg;</sup> (naloxegol). Prescribing information. Valinor Pharma, LLC; 2023. <span className="bold">2.</span> Chey WD, Webster L, Sostek M, Lappalainen J, Barker PN, Tack J. Naloxegol for opioid-induced constipation in patients with noncancer pain. <em>N Engl J Med.</em> 2014;370(25):2387-2396.</div>
        </div></div>
      </div>
    </Layout>
  )
}

export default Dosing
